export enum Tabs {
  Services = 'services-tab',
  Details = 'details-tab',
  Opinions = 'opinions-tab',
  Brands = 'brands-tab'
}

export enum EHomePageSearchStep {
  ServiceOrWorkshop = 'service-or-workshop',
  CityOrDistrict = 'city-or-district'
}